.scriptsPane {
  :global(.react-codemirror2) {
    display: flex;
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid var(--sand_300);
  }

  :global(.CodeMirror) {
    height: 100%;
    width: 100%;
  }
}

:global(.lsf-configure__editor_dynamic) {
  .customScripts {
    padding: 1rem;

    :global(.react-codemirror2) {
      border: 1px solid var(--sand_300);
    }
  }

  :global(.react-codemirror2) {
    border: 0;
  }
}
